@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.App {
  text-align: center;
}

.brand{
  font-family: 'Rock Salt';
  /* color: #141e30 !important; */
  font-size: 5vw;
  padding-bottom: 50px;
}


.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header-2 {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  background: -webkit-linear-gradient(rgba(20, 30, 48, 1), rgba(36, 59, 85, 1));
  color: white
}

.jumbotron {
  /* #141e30 → #243b55 */
  background: -webkit-linear-gradient(rgba(20, 30, 48, 1), rgba(36, 59, 85, 1));
  color: white;
  height: auto ;
  width: 100%;
  text-align: center;
}

.jumbotron-2 {
  /* #141e30 → #243b55 */
  background: white !important;
  height: auto ;
  width: 100%;
  text-align: center;
  color: #141e30 !important;
}

.jumboText {
  max-width: auto;
  margin: 0px auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.full-container{
  width: 100%;
}

.section{
  height: 500px;
  width: 100%;
  text-align: center;
  background: red
}

.p-text-blue{
  color: #141e30 !important;
}

.message-input {
  height: 200px;
}

.submit-button {
  background: -webkit-linear-gradient(rgba(20, 30, 48, 1), rgba(36, 59, 85, 1)) !important;
  color: white !important
}



